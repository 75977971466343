<template>
  <v-sheet height="100%" class="embeded-jobs">
    <v-container class="fill-height justify-center" fluid>
      <h2 class="text-center">Bolsa de empleo</h2>
      <v-row justify="center" align="center" no-gutters>
        <v-img
          :src="identity && identity.logo ? identity.logo.route : ''"
          max-width="200"
          min-width="150"
          alt="logo"
          height="100"
        />
        <v-col v-if="company && company.id">
          <v-card-title>{{ company.name }}</v-card-title>
          <v-card-text>
            <span>
              {{ company.address }}
            </span>
            <br />

            <span>{{ company.description }}</span>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" class="mx-0 my-4">
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="mx-auto"
          v-for="(item, i) in items"
          :key="i"
        >
          <v-hover style="padding: 0px" v-slot="{ hover }">
            <v-card
              :elevation="3"
              color="grey lighten-4 news-box"
              @click="handlePostulationModal(item)"
            >
              <v-img
                @click="handlePostulationModal(item)"
                :aspect-ratio="16 / 9"
                max-width="95%"
                class="mx-auto"
                :src="item.cover ? item.cover.route : ''"
                contain
              >
                <v-expand-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#000000"
                    class="d-flex transition-fast-in-fast-out v-card--reveal text-white justify-center align-center overlay"
                  >
                    <!-- <v-btn small color="primary" @click="handlePostulationModal(item)">
                      Postular
                    </v-btn> -->
                    <span class="overline"> Postularse</span>
                  </v-overlay>
                </v-expand-transition>
              </v-img>
              <v-card-text class="pt-6" style="position: relative; height: 100%">
                <v-row align="center" class="mx-0">
                  <v-col cols="12" class="text-center font-weight-bold font-body-1 pa-1">
                    {{ item.position && item.position.name ? item.position.name : '' }}
                  </v-col>
                  <v-col cols="12" class="pa-1">
                    <v-icon color="primary darken-2" class="mr-1"> mdi-domain </v-icon>
                    Vacantes: {{ item.vacancies }}
                  </v-col>

                  <v-col cols="12" class="pa-1">
                    <v-icon color="primary darken-2" class="mr-1"> mdi-clock </v-icon>

                    {{ timeOptions.find((option) => option.id === item.time).value }}
                  </v-col>

                  <v-col cols="12" class="pa-1 text-truncate">
                    <v-icon color="primary darken-2" class="mr-1"> mdi-cash </v-icon>

                    Salario Estimado: {{ item.estimated_salary }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" no-gutters class="mb-4">
        <ShareNetwork
          network="facebook"
          :url="url"
          :title="sharedTitle"
          :description="sharedDescription"
        >
          <i class="fab fah fa-lg fa-facebook" />
          Facebook
        </ShareNetwork>

        <ShareNetwork
          network="twitter"
          :url="url"
          :title="sharedTitle"
          :description="sharedDescription"
        >
          <i class="fab fah fa-lg fa-twitter" />
          Twitter
        </ShareNetwork>
        <ShareNetwork
          network="whatsapp"
          :url="url"
          :title="sharedTitle"
          :description="sharedDescription"
        >
          <i class="fab fah fa-lg fa-whatsapp" />
          WhatsApp
        </ShareNetwork>
        <ShareNetwork
          network="telegram"
          :url="url"
          :title="sharedTitle"
          :description="sharedDescription"
        >
          <i class="fab fah fa-lg fa-telegram" />
          Telegram
        </ShareNetwork>
        <ShareNetwork
          network="email"
          :url="url"
          :title="sharedTitle"
          :description="sharedDescription"
        >
          <v-icon class="fah" dark>mdi-email</v-icon>
          Email
        </ShareNetwork>
      </v-row>
      <v-row justify="center" align="center" no-gutters>
        Powered by
        <img src="/static/default/logo.png" class="ml-1" width="150" alt="logo" />
      </v-row>
      <modal name="postulationOfferModal" :height="'80%'" :width="'90%'" :adaptive="true">
        <v-stepper v-model="e1" class="pa-2 pa-md-4" flat>
          <v-stepper-header flat>
            <v-stepper-step :complete="e1 > 1" step="1" editable
              >Información general
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="e1 > 2" step="2" editable>
              Competencias
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="e1 > 3" step="3">
              Datos Personales
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row align="center" class="mx-0" v-if="selectedOffer">
                <v-col
                  cols="12"
                  class="text-center font-weight-bold text-body-1 my-2 pa-1"
                >
                  {{ selectedOffer.position.name }}
                </v-col>
                <v-col cols="12" md class="pa-1">
                  <v-icon color="primary darken-2" class="mr-1"> mdi-domain </v-icon>
                  Vacantes: {{ selectedOffer.vacancies }}
                </v-col>

                <v-col cols="12" md class="pa-1">
                  <v-icon color="primary darken-2" class="mr-1"> mdi-clock </v-icon>

                  {{
                    timeOptions.find((option) => option.id === selectedOffer.time).value
                  }}
                </v-col>

                <v-col cols="12" md class="pa-1">
                  <v-icon color="primary darken-2" class="mr-1"> mdi-cash </v-icon>

                  Salario Estimado: {{ selectedOffer.estimated_salary }}
                </v-col>
              </v-row>
              <vue-editor
                class="my-4 pa-4"
                v-if="selectedOffer && selectedOffer.description"
                :editor-toolbar="descriptionToolbar"
                v-model="selectedOffer.description"
              />
              <v-row justify="center" no-gutters>
                <v-btn small color="primary" @click="e1 = 2"> Continuar </v-btn>
                <v-btn small text @click="closePostulationModal()"> Cerrar </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-simple-table class="mb-4" v-if="selectedOffer">
                <template v-slot:default>
                  <thead>
                    <tr class="">
                      <th class="text-left text-body-1 font-weight-bold">
                        Competencias del cargo
                      </th>
                      <!-- <th class="text-left">Minimo Esperado</th> -->
                      <!-- <th class="text-left text-body-1 font-weight-bold">Condición</th> -->
                      <th class="text-left text-body-1 font-weight-bold">Respuesta</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(positionSkill, i) in selectedOffer.position.position_skills"
                      :key="i"
                    >
                      <td>{{ positionSkill.skill.name }}</td>
                      <!-- <td>{{ item.minimun }}</td> -->
                      <!-- <td>
                        <b>{{
                          handleSkillValue(positionSkill.skill.field_type, positionSkill)
                        }}</b>
                      </td> -->
                      <td>
                        <v-text-field
                          style="max-width: 100px"
                          placeholder="Valor asignado"
                          v-model="form.applicantAttrs[i].value"
                          :step="
                            positionSkill.skill.field_type === 'integer' ? '1' : '0.01'
                          "
                          v-if="positionSkill.skill.field_type !== 'boolean'"
                          type="number"
                          hide-details="auto"
                          min="0"
                        />
                        <v-radio-group
                          style="max-width: 150px"
                          v-else
                          dense
                          hide-details
                          mandatory
                          v-model="form.applicantAttrs[i].value"
                          :column="false"
                        >
                          <v-row align="center">
                            <v-radio label="Si" :value="1" class="mx-2" />
                            <v-radio label="No" :value="0" class="mx-2" />
                          </v-row>
                        </v-radio-group>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-row justify="center" no-gutters>
                <v-btn small text @click="e1 = --e1"> Regresar </v-btn>
                <v-btn small color="primary" @click="e1 = 3"> Continuar </v-btn>
                <v-btn small text @click="closePostulationModal()"> Cerrar </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-form ref="form" v-model="form.validation">
                <v-row class="mx-0 mb-4">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      label="Nombre*"
                      v-model="form.name"
                      :rules="[rules.required, rules.alphanumWithSpace]"
                      prepend-inner-icon="person"
                      flat
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      label="Apellido*"
                      v-model="form.lastname"
                      :rules="[rules.required, rules.alphanumWithSpace]"
                      prepend-inner-icon="person"
                      flat
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      :rules="[rules.required, rules.email]"
                      prepend-inner-icon="email"
                      v-model="form.email"
                      label="Correo electrónico*"
                      flat
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      :rules="[rules.required]"
                      prepend-inner-icon="phone"
                      v-model="form.phone"
                      label="Telefono Personal*"
                      flat
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      prepend-inner-icon="phone"
                      v-model="form.local_phone"
                      label="Telefono Fijo"
                      flat
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      :prepend-inner-icon="'mdi-map-marker'"
                      v-model="form.address"
                      :rules="[rules.required]"
                      label="Dirección*"
                      flat
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      dense
                      :items="genreOptions"
                      label="Género*"
                      v-model="form.genre"
                      item-text="value"
                      item-value="id"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      v-model="form.birthday"
                      type="date"
                      :rules="[rules.required]"
                      label="Fecha de Nacimiento*"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      dense
                      :items="identificationOptions"
                      label="Tipo de documentación*"
                      v-model="form.identification_type"
                      item-text="value"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      v-model="form.identification_num"
                      label="Número de identificación*"
                      :rules="[rules.required, rules.alphanumNoSpace]"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-file-input
                      dense
                      label="Sube tu Currículum*"
                      flat
                      show-size
                      accept=".pdf"
                      @change="uploadCV($event)"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-file-input
                      dense
                      label="Sube una foto tipo carnet*"
                      flat
                      show-size
                      accept=".png, .jpg, jpeg, .webp"
                      @change="uploadAvatar($event)"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      auto-grow
                      rows="1"
                      v-model="form.message"
                      label="Mensaje*"
                      flat
                      counter="200"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-row justify="center" no-gutters>
                <v-btn small text @click="e1 = --e1"> Regresar </v-btn>
                <v-btn
                  small
                  color="primary"
                  @click="saveApplication()"
                  :disabled="!form.validation && !form.loadedCV && !form.loadedAvatar"
                >
                  Postularte
                </v-btn>
                <v-btn small text @click="closePostulationModal()"> Cerrar </v-btn></v-row
              >
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </modal>
    </v-container>
  </v-sheet>
</template>

<script>
  /* eslint-disable */
  import { base64Decode } from '@/utils/encryption'

  export default {
    name: 'Jobs',
    data() {
      return {
        config: {},
        hover: false,
        companyId: base64Decode(this.$route.params.companyId),
        identity: null,
        company: null,
        e1: 1,
        descriptionToolbar: [[]],
        form: {
          validation: false,
          name: '',
          lastname: '',
          email: '',
          phone: '',
          local_phone: '',
          address: '',
          genre: '',
          birthday: '',
          message: '',
          identification_type: '',
          identification_num: '',
          validFiles: false,
          files: [
            { file: null, fileType: 'cv' },
            { file: null, fileType: 'avatar' },
          ],
          applicantAttrs: [],
        },
        page: 1,
        pages: 1,
        items: [],
        selectedOffer: null,
        loadingPositionOffers: false,
        timeOptions: [
          { id: 0, value: 'Tiempo Completo' },
          { id: 1, value: 'Tiempo Parcial' },
        ],
        typeOptions: [
          { id: 0, value: 'Educación' },
          { id: 1, value: 'Experiencia' },
          { id: 2, value: 'Habilidades' },
        ],
        genreOptions: [
          { id: 0, value: 'Masculino' },
          { id: 1, value: 'Femenino' },
        ],
        identificationOptions: [
          { id: 0, value: 'DNI' },
          { id: 1, value: 'Pasaporte' },
        ],
        rules: {
          // eslint-disable-next-line no-unneeded-ternary
          required: (v) =>
            (v !== null && v !== '' && v !== undefined ? true : false) || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',

          alphanumWithSpace: (v) =>
            (v ? /^[a-zA-Z0-9À-ÿ ]+$/.test(v) : true) || 'Caracter invalido',
          alphanumNoSpace: (v) =>
            (v ? /^[a-zA-Z0-9À-ÿ]+$/.test(v) : true) || 'Caracter invalido',

          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
      }
    },
    computed: {
      url() {
        return process.env.VUE_APP_WEBSITE + this.$route.fullPath.substring(1)
      },
      sharedTitle() {
        if (this.identity && this.identity.name) {
          return `${this.identity.name} - Bolsa de empleo`
        }
        return ''
      },
      sharedDescription() {
        // if (this.identity && this.identity.name) {
        //   return `${this.identity.name} - Bolsa de empleo`
        // }
        return ''
      },
    },
    created() {
      this.getIdentity()
      this.getCompany()
      this.getPositionOffers()
    },
    methods: {
      getCompany() {
        this.$axios.get('companies/' + this.companyId).then((response) => {
          this.company = response.data
        })
      },
      async getPositionOffers() {
        this.loadingPositionOffers = true
        let queryParams = ''
        const orderString = ''
        const searchString = ''
        const withString = '&with[]=position.positionSkills.skill&with[]=cover'

        queryParams = searchString + withString

        this.$axios
          .get(
            'position-offers?active=1&company_id=' +
              this.companyId +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loadingPositionOffers = false
          })
      },
      async saveApplication() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }
        const formData = new FormData()
        formData.append('position_offer_id', this.selectedOffer.id)
        formData.append('name', this.form.name)
        formData.append('lastname', this.form.lastname)
        formData.append('email', this.form.email)
        formData.append('phone', this.form.phone)
        formData.append('local_phone', this.form.local_phone)
        formData.append('address', this.form.address)
        formData.append('genre', this.form.genre)
        formData.append('birthday', this.form.birthday)
        formData.append('identification_type', this.form.identification_type)
        formData.append('identification_num', this.form.identification_num)
        formData.append('message', this.form.message)
        formData.append('status', 0)
        this.form.files.forEach((uploadedFile, index) => {
          if (uploadedFile.value !== null) {
            formData.append('files[' + index + '][file]', uploadedFile.file)
            formData.append('files[' + index + '][fileType]', uploadedFile.fileType)
          }
        })

        if (this.form.applicantAttrs.length > 0) {
          this.form.applicantAttrs.forEach((attr, index) => {
            if (attr.value !== null) {
              formData.append(
                'applicantAttrs[' + index + '][position_skill_id]',
                attr.position_skill_id
              )
              formData.append('applicantAttrs[' + index + '][value]', attr.value)
            }
          })
        }

        this.$axios.post('job-applications', formData, this.config).then(() => {
          this.closePostulationModal()
        })
      },
      getIdentity() {
        this.$axios
          .get('identities?limit=1&order=created_at&by=desc')
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.identity = response.data[0]
            } else {
              this.identity = null
            }
          })
      },
      handleSkillValue(type, skill) {
        switch (type) {
          case 'integer':
            return parseInt(skill.value)
          case 'number':
            return skill.value
          case 'boolean':
            return 'Requerido'

          default:
            return null
        }
      },
      handlePostulationModal(offer) {
        offer.position.position_skills.map((positionSkill) =>
          this.form.applicantAttrs.push({
            position_skill_id: positionSkill.id,
            value: 0,
          })
        )
        this.selectedOffer = offer
        this.$modal.show('postulationOfferModal')
      },
      closePostulationModal() {
        this.e1 = 1
        this.reset()
        this.$modal.hide('postulationOfferModal')
      },
      reset() {
        this.form = {
          name: '',
          lastname: '',
          email: '',
          phone: '',
          local_phone: '',
          address: '',
          genre: null,
          birthday: null,
          message: '',
          identification_type: null,
          identification_num: null,
          files: [
            { file: null, fileType: 'cv' },
            { file: null, fileType: 'avatar' },
          ],
          applicantAttrs: [],
          loadedCV: false,
          loadedAvatar: false,
        }
      },
      uploadCV(uploadedFile) {
        this.form.files.find((option) => option.fileType === 'cv').file = uploadedFile
        this.form.loadedAvatar = true
      },
      uploadAvatar(uploadedFile) {
        this.form.files.find((option) => option.fileType === 'avatar').file = uploadedFile
        this.form.loadedCV = true
      },
    },
  }
</script>
<style lang="scss">
  .embeded-jobs {
    // .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    //   margin-bottom: 0;
    // }
    #share-network-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 130px;
      margin: auto;
    }

    a[class^='share-network-'] {
      flex: none;
      color: #ffffff;
      background-color: #333;
      border-radius: 3px;
      overflow: hidden;
      display: inline-block;
      flex-direction: row;
      align-content: center;
      align-items: center;
      cursor: pointer;
      width: 130px;
      margin: 0 10px 10px 0;
      text-decoration: none;
    }

    a[class^='share-network-'] .fah {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 10px;
      flex: 0 1 auto;
    }

    a[class^='share-network-'] span {
      padding: 0 10px;
      flex: 1 1;
      font-weight: 500;
    }

    a.share-network-twitter {
      background-color: #1da1f2;
    }
    a.share-network-facebook {
      background-color: #0a73f7;
    }
    a.share-network-whatsapp {
      background-color: #51ec6c;
    }
    a.share-network-telegram {
      background-color: #26a2e0;
    }
    a.share-network-email {
      background-color: #bf201e;
      .v-icon {
        font-size: 19px;
      }
    }
    .container {
      max-width: 1000px;
    }
    .quillWrapper {
      .ql-toolbar.ql-snow {
        display: none;
      }
      .ql-container.ql-snow {
        border: none;
      }
      height: inherit;
    }
  }
</style>
